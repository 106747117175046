export const INIT = "INIT";

export const init = (baseURL: string, supportedLanguages: string[], defaultLanguage: string, configs: any) => ({
  type: INIT,
  payload: {
    baseURL,
    supportedLanguages,
    defaultLanguage,
    appConfig: configs?.appConfig || null,
    footerHtmlStr: configs?.footerHtmlStr || null,
    dashboardConfig: configs?.dashboardConfig || null,
    externalServices: configs?.externalServices || null
  }
});
