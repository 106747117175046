import { getSubmitStatisticsUrl } from "../../serverApi/urls";
import { initRequest, RequestMethod } from "../request/requestActions";

export const DATASET_STATISTICS_SUBMIT = "dataset/statistics/submit";

export const submitDatasetStatistics = (baseUrl: string, datasetLabel: string, nodeCode: string) => initRequest(
  DATASET_STATISTICS_SUBMIT,
  getSubmitStatisticsUrl(),
  RequestMethod.POST,
  {
    Dataset: datasetLabel,
    Node: nodeCode
  },
  undefined,
  undefined,
  baseUrl,
  () => true
);