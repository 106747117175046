import { DATASET_FETCH } from "../../state/dataset/datasetActions";
import { REQUEST_INIT } from "../request/requestActions";
import { submitDatasetStatistics } from "./actions";

const datasetStatisticsMiddleware = ({dispatch, getState}) => next => action => {

    const state = getState();    
    const result = next(action);
  
    if (action?.payload?.label === DATASET_FETCH) {  
      if (action.type === REQUEST_INIT) {
        const statisticBaseUrl = state.config?.externalServices?.statistic;
        const label = state.catalog.datasets[state.dataset.datasetId].title || state.dataset.datasetId;
        const nodeCode = state.node.code;
        dispatch(submitDatasetStatistics(statisticBaseUrl, label,nodeCode));  
      }
    }
    return result;

};

export default datasetStatisticsMiddleware;