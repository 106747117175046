import React from "react";
import {compose} from "redux";
import {connect} from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import {withTranslation} from "react-i18next";
import SanitizedHTML from "../sanitized-html";
import Divider from "@material-ui/core/Divider";

const styles = theme => ({
  footer: {
    margin: "auto 16px 0 16px",
    paddingTop: 24
  }
});

const Footer = ({
                  t,
                  classes,
                  footerHtmlStr
                }) =>
  <footer id="footer" className={classes.footer}>
    <Divider id="footer__divider" className={classes.divider}/>
    <nav id="footer__content" aria-label={t("components.footer.ariaLabel")}>
      <SanitizedHTML html={footerHtmlStr}/>
    </nav>
  </footer>;

export default compose(
  connect(state => ({
    footerHtmlStr: state.app.footerHtmlStr
  })),
  withStyles(styles),
  withTranslation()
)(Footer);