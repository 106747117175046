import {configureStore} from "@reduxjs/toolkit";
import rootReducer from "./state/rootReducer";
import userMiddlewareFactory from "./middlewares/user/userMiddlewareFactory";
import requestMiddlewareFactory from "./middlewares/request/requestMiddlewareFactory";
import {showGenericError, showTranslatedGenericErrorFactory} from "./utils/other";
import configMiddleware from "./middlewares/config/configMiddleware";
import fileSaveMiddleware from "./middlewares/file-save/middleware";
import persistenceMiddleware from "./middlewares/persistence/middleware";
import i18nMiddleware from "./middlewares/i18n-middleware/middleware";
import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import {init as initAction} from "./state/rootActions";
import requestSpinnerMiddlewareFactory from "./middlewares/request-spinner/requestSpinnerMiddlewareFactory";
import {getAppConfigUrl, getDashboardConfigUrl, getFooterHtmlUrl, getHubMinimalInfoUrl, getInitConfigUrl} from "./serverApi/urls";
import feedbackMiddlewareFactory from "./middlewares/feedback/feedbackMiddleware";
import fetchDatasetAsyncHandlerMiddlewareFactory from "./middlewares/fetchDatasetAsyncHandler/middlewareFactory";
import fetchDashboardDatasetAsyncHandlerMiddlewareFactory from "./middlewares/fetchDashboardDatasetAsyncHandler/middlewareFactory";
import a11yMiddleware from "./middlewares/a11y-middleware/middleware";
import nodeMiddleware from "./middlewares/node/middleware";
import actionDecoratorMiddlewareFactory from "./middlewares/action-decorator/actionDecoratorMiddlewareFactory";
import _ from "lodash";
import datasetStatisticsMiddleware from "./middlewares/statistics/middleware";

const getRandomParam = () => "?random=" + Math.floor(Math.random() * 16777215).toString(16);

const configFiles = [
  {
    url: getAppConfigUrl(),
    isJson: true,
    param: "appConfig"
  },
  {
    url: getFooterHtmlUrl(),
    isJson: false,
    param: "footerHtmlStr"
  },
  {
    url: getDashboardConfigUrl(),
    isJson: true,
    param: "dashboardConfig"
  }
];

const getUrlWithFinalSlash = url => url.endsWith("/") ? url : (url + "/");

const init = cb => {
  const configs = {};
  fetch(`./${getInitConfigUrl()}${getRandomParam()}`)
    .then(response => response.json())
    .then(({baseURL: origBaseURL, externalServices}) => {
      
      const baseURL = getUrlWithFinalSlash(origBaseURL);     
      configs.externalServices = _.mapValues(externalServices, getUrlWithFinalSlash);      
      Promise.all(configFiles.map(config =>
        fetch(`./${config.url}${getRandomParam()}`)
          .then(response => config.isJson
            ? response.headers.get("content-type").indexOf("application/json") !== -1
              ? response.json()
              : null
            : response.text()
          )
          .catch(showGenericError)
      ))
        .then(responses => {          
          responses.forEach((config, idx) => {
            configs[configFiles[idx].param] = config;
          });
        })
        .then(() => {
          fetch(`${baseURL}${getHubMinimalInfoUrl()}`)
            .then(response => response.json())
            .then(({hub}) => {
              const supportedLanguages = ["en", ...hub.supportedLanguages.filter(lang => lang !== "en")];
              Promise.all(supportedLanguages.map(code =>
                fetch(`./i18n/${code}.json${getRandomParam()}`)
                  .then(response => response.headers.get("content-type").indexOf("application/json") !== -1
                    ? response.json()
                    : null
                  )
                  .catch(showGenericError)
              ))
                .then(translations => {

                  let resources = {};
                  supportedLanguages.forEach((code, idx) => {
                    if (translations[idx]) {
                      resources[code] = {
                        translation: translations[idx]
                      };
                    }
                  });

                  let supportedAndTranslatedLanguages = Object.keys(resources);
                  if (supportedAndTranslatedLanguages.length < 2) {
                    console.error("Unable to find translation file for at least one configured language. Please check app configuration and translations file.");
                  } else if (!hub.supportedLanguages.includes("en")) {
                    supportedAndTranslatedLanguages = supportedAndTranslatedLanguages.filter(lang => lang !== "en");
                    delete resources["en"];
                  }

                  const defaultLanguage = resources[hub.defaultLanguage]
                    ? hub.defaultLanguage
                    : supportedAndTranslatedLanguages[0];

                  i18n
                    .use(initReactI18next)
                    .init({
                      lng: defaultLanguage,
                      resources,
                      returnEmptyString: false,
                      interpolation: {
                        escapeValue: false
                      }
                    });

                  const store = configureStore({
                    reducer: rootReducer,
                    middleware: [
                      userMiddlewareFactory(i18n.t.bind(i18n)),
                      requestMiddlewareFactory({
                        onGenericError: showTranslatedGenericErrorFactory(i18n.t.bind(i18n))
                      }),
                      requestSpinnerMiddlewareFactory(i18n.t.bind(i18n)),
                      fileSaveMiddleware,
                      persistenceMiddleware,
                      i18nMiddleware,
                      a11yMiddleware,
                      configMiddleware,
                      nodeMiddleware,
                      feedbackMiddlewareFactory(i18n.t.bind(i18n)),
                      fetchDatasetAsyncHandlerMiddlewareFactory(i18n.t.bind(i18n)),
                      fetchDashboardDatasetAsyncHandlerMiddlewareFactory(i18n.t.bind(i18n)),
                      actionDecoratorMiddlewareFactory(i18n.t.bind(i18n)),
                      datasetStatisticsMiddleware
                    ]
                  });

                  store.dispatch(initAction(baseURL, supportedAndTranslatedLanguages, defaultLanguage, configs));
                  cb(store);
                })
                .catch(showGenericError);
            })
            .catch(showGenericError);
        })
        .catch(showGenericError);
    })
    .catch(showGenericError);
};

export default init;