import React, {useEffect, useState} from 'react';
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {Route, Switch} from "react-router";
import Node from "../components/node";
import {clearNode, fetchNode} from "../state/node/nodeActions";
import {clearCatalog, fetchCatalog} from "../state/catalog/catalogActions";
import Results from "../components/results";
import DatasetDomain from "./DatasetDomain";
import {connect} from "react-redux";
import DashboardsDomain from "./DashboardsDomain";
import {goToNode} from "../links";
import Call from "../hocs/call";
import Categories from "../components/categories";
import {submitDatasetDownload} from "../state/dataset/datasetActions";
import {DOWNLOAD_FORMAT_CSV, getDownloadFormatExtensionFromFormat} from "../utils/download";
import {LABEL_FORMAT_SELECTOR_LABEL_FORMAT_NAME} from "../components/label-format-selector/constants";

const mapStateToProps = state => ({
  hub: state.hub,
  node: state.node,
  catalog: state.catalog,
  baseURL: state.config.baseURL
});

const mapDispatchToProps = dispatch => ({
  fetchNode: ({nodeId, nodeCode}) => dispatch(fetchNode(nodeId, nodeCode)),
  fetchCatalog: ({nodeId, nodeCode}) => dispatch(fetchCatalog(nodeId, nodeCode)),
  clearNode: () => dispatch(clearNode()),
  clearCatalog: () => dispatch(clearCatalog()),
  onDownloadSubmit: (nodeId, datasetId, datasetTitle, format, extension, zipped, criteria, layout, params, t) =>
    dispatch(submitDatasetDownload(nodeId, datasetId, datasetTitle, format, extension, zipped, criteria, layout, params, t)),
});

const getDataset = (datasetId, catalog) => {
  if (!datasetId || !catalog) {
    return null;
  }

  const categorizedDataset = catalog.datasets[datasetId] || null;
  const uncategorizedDataset = catalog.uncategorizedDatasets.find(({identifier}) => identifier === datasetId) || null;

  return categorizedDataset || uncategorizedDataset;
};

const getCategory = (categoryPath, catalog) => {
  if (!categoryPath || !catalog) {
    return null;
  }

  let res = null;

  if (catalog.categoryGroups.length === 0) {
    return null;

  } else if (catalog.categoryGroups.length === 1) {
    const category = (catalog.categoryGroups[0]?.categories || []).find(({id}) => id === categoryPath[0]);

    if (!category) {
      return null;
    }

    if (categoryPath.length === 1) {
      res = category;

    } else {
      let tree = category.childrenCategories;
      categoryPath.slice(1).forEach((elem, idx, arr) => {
        if (idx < arr.length - 1) {
          tree = tree.find(({id}) => id === elem)?.childrenCategories || [];
        } else {
          res = tree.find(({id}) => id === elem) || null;
        }
      });
    }

  } else {
    const categorySchema = catalog.categoryGroups.find(({id}) => id === categoryPath[0]);

    if (!categorySchema) {
      return null;
    }

    if (categoryPath.length === 1) {
      res = {
        childrenCategories: categorySchema.categories,
        datasetIdentifiers: []
      }

    } else {
      let tree = categorySchema.categories;
      categoryPath.slice(1).forEach((elem, idx, arr) => {
        if (idx < arr.length - 1) {
          tree = tree.find(({id}) => id === elem)?.childrenCategories || [];
        } else {
          res = tree.find(({id}) => id === elem) || null;
        }
      });
    }
  }

  return res;
};

const NodeDomain = ({
                      t,
                      hub,
                      nodeCode,
                      node,
                      catalog,
                      baseURL,
                      isDefault,
                      fetchNode,
                      clearNode,
                      fetchCatalog,
                      clearCatalog,
                      onDownloadSubmit
                    }) => {

  const [accessibleDataset, setAccessibleDataset] = useState(null);

  useEffect(() => {
    return () => {
      clearNode();
      clearCatalog();
    }
  }, [clearNode, clearCatalog]);

  const nodeHavingCode = hub.nodes.find(({code}) => code.toLowerCase() === nodeCode.toLowerCase());

  const isFetchNodeEnabled = hub && (!node || node.code.toLowerCase() !== nodeCode.toLowerCase());

  const areNodeAndCatalogOk = node && catalog && node.code.toLowerCase() === nodeCode.toLowerCase() && node.nodeId === catalog.nodeId;

  return (
    <div style={{width: "100%", height: "100%"}} id={node?.code ? ("node__" + node.code) : null}>
      <Call
        cb={fetchNode}
        cbParam={{nodeId: nodeHavingCode.nodeId, nodeCode: nodeCode}}
        disabled={!isFetchNodeEnabled}
      >
        <Call
          cb={fetchCatalog}
          cbParam={{nodeId: nodeHavingCode.nodeId, nodeCode: nodeCode}}
          disabled={isFetchNodeEnabled || !!catalog}
        >
          <Switch>
            <Route
              path={['/', '/:lang', '/:lang/:nodeCode']}
              exact
              render={props => {
                return (
                  <Node
                    {...props}
                    nodeCode={nodeCode}
                    hub={hub}
                    node={node}
                    catalog={catalog}
                    isDefault={isDefault}
                  />
                )
              }}
            />
            <Route
              path='/:lang/:nodeCode/dashboards'
              exact
              render={() => {
                return (
                  <DashboardsDomain
                    nodeCode={nodeCode}
                    isDefault={isDefault}
                  />
                )
              }}
            />
            <Route
              path='/:lang/:nodeCode/search'
              exact
              render={({location}) => {
                if (areNodeAndCatalogOk) {

                  const datasetIdParam = new URLSearchParams(location.search).get("datasetId");
                  const query = new URLSearchParams(location.search).get("q");
                  const filtersParams =
                    (new URLSearchParams(location.search).get("c") || "")
                      .split("//").filter(str => str.length > 0).map(decodeURIComponent)
                      .map(filter => filter.split("/")).filter(path => path.length > 0);
                  let filters = [];

                  filtersParams.forEach(filter => filter.forEach(id => filters.push(id)));
                  filters = [...new Set(filters)]; // remove duplicates

                  if (query && query.length > 0) {

                    const testDSForQuery = ds =>
                      (ds.title || "").toLowerCase().includes(query.toLowerCase()) ||
                      !!ds.keywords?.find(keyword => keyword.toLowerCase().includes(query.toLowerCase()));

                    const testCatForFilters = cat => filters.length === 0 || filters.includes(cat.id);

                    const dssForCount = [];   // satisfy only query (necessary to display count numbers)
                    const dssForResults = []; // satisfy query and filters

                    if (catalog.uncategorizedDatasets && catalog.uncategorizedDatasets.length > 0) {
                      catalog.uncategorizedDatasets.forEach(ds => {
                        ds.categoryPath = ["uncategorized"];

                        if (testDSForQuery(ds)) {
                          dssForCount.push(ds);
                          if (filters.length === 0 || filters.includes("uncategorized")) {
                            dssForResults.push(ds);
                          }
                        }
                      });
                    }

                    const searchRecursive = (categories, categoryPath, overrideCatSatisfyFilters = false) => {
                      categories.forEach(c => {
                        const catSatisfyFilters = overrideCatSatisfyFilters || testCatForFilters(c);

                        c.datasetIdentifiers.forEach(id => {
                          const ds = {
                            ...catalog.datasets[id],
                            identifier: id,
                            categoryPath: [...categoryPath, c.id]
                          };
                          if (testDSForQuery(ds)) {
                            dssForCount.push(ds);
                            if (catSatisfyFilters) {
                              dssForResults.push(ds);
                            }
                          }
                        });

                        searchRecursive(c.childrenCategories, [...categoryPath, c.id], catSatisfyFilters);
                      });
                    };

                    if (catalog.categoryGroups.length > 1) {
                      // with category schemes
                      catalog.categoryGroups.forEach(group => searchRecursive(group.categories, [group.id]));
                    } else if (catalog.categoryGroups.length === 1) {
                      // no category schemes
                      searchRecursive(catalog.categoryGroups[0].categories, []);
                    }

                    const dssSatisfyQueryMap = {};
                    dssForCount.forEach(ds => dssSatisfyQueryMap[ds.identifier] = ds);

                    const recursiveCountAndFilterZero = (tree, visited) => tree
                      .map(node => {
                        const children = recursiveCountAndFilterZero(node.childrenCategories, visited).filter(({count}) => count > 0);

                        const uniqueDssSatisfyQueryCount = node.datasetIdentifiers?.filter(dsId => {
                          if (dssSatisfyQueryMap[dsId] !== undefined) {
                            if (visited[dsId]) {
                              return false;
                            } else {
                              visited[dsId] = true;
                              return true;
                            }
                          } else {
                            return false;
                          }
                        }).length || 0;

                        return ({
                          ...node,
                          childrenCategories: node.childrenCategories ? children : undefined,
                          count: children.reduce((acc, node) => acc + node.count, 0) + uniqueDssSatisfyQueryCount
                        });
                      })
                      .filter(({count}) => count > 0);

                    const categoryGroupsWithCount = catalog.categoryGroups
                      .map(categoryGroup => {
                        const categories = (categoryGroup?.categories || [])
                          .map(category => {
                            const visited = {};
                            const childrenCategories = recursiveCountAndFilterZero(category.childrenCategories, visited);
                            return {
                              ...category,
                              childrenCategories: childrenCategories,
                              count: childrenCategories.reduce((acc, node) => acc + node.count, 0) +
                                (category.datasetIdentifiers?.filter(dsId => dssSatisfyQueryMap[dsId] !== undefined).length || 0)
                            }
                          })
                          .filter(({count}) => count > 0);
                        return {
                          ...categoryGroup,
                          categories: categories,
                          count: categories.reduce((acc, node) => acc + node.count, 0)
                        }
                      })
                      .filter(({count}) => count > 0);

                    const uncategorizedDatasetsCount = catalog.uncategorizedDatasets.filter(({identifier}) => dssSatisfyQueryMap[identifier] !== undefined).length;

                    // remove duplicates from results ( O(2n) )
                    const resultsMap = {};
                    dssForResults.forEach(ds => resultsMap[ds.identifier] = ds);

                    const a11yParam = new URLSearchParams(location.search).get("accessible");

                    return (
                      <Results
                        query={query}
                        filters={filtersParams}
                        filtered
                        hub={hub}
                        node={node}
                        nodeCode={nodeCode}
                        isDefault={isDefault}
                        catalog={catalog}
                        datasets={Object.keys(resultsMap).map(id => resultsMap[id])}
                        categoryGroupsWithCount={categoryGroupsWithCount}
                        uncategorizedDatasetsCount={uncategorizedDatasetsCount}
                        scrollToDatasetId={datasetIdParam}
                        isAccessible={!!a11yParam}
                        onAccessibleDatasetFetch={setAccessibleDataset}
                      />
                    );

                  } else {
                    goToNode(nodeCode);
                  }
                }
              }}
            />
            <Route
              path='/:lang/:nodeCode/categories'
              exact
              render={() => {
                if (areNodeAndCatalogOk) {
                  return catalog.isEmpty
                    ? (
                      <Call cb={goToNode} cbParam={nodeCode}>
                        <span />
                      </Call>
                    )
                    : (
                      /* list of categories */
                      <Categories
                        hub={hub}
                        nodeCode={nodeCode}
                        node={node}
                        catalog={catalog}
                        isDefault={isDefault}
                      />
                    );
                }
              }}
            />
            <Route
              path='/:lang/:nodeCode/categories/*'
              exact
              render={({match, location}) => {
                if (areNodeAndCatalogOk) {

                  const a11yParam = new URLSearchParams(location.search).get("accessible");
                  const viewParam = new URLSearchParams(location.search).get("view");
                  const fullPath = match.params[0].endsWith("/")
                    ? match.params[0].slice(0, -1).split("/")
                    : match.params[0].split("/");

                  if (catalog.isEmpty) {
                    return (
                      <Call cb={goToNode} cbParam={nodeCode}>
                        <span />
                      </Call>
                    );

                    /* list of datasets */
                  } else if ((fullPath.length === 1 && fullPath[0] === "uncategorized") || getCategory(fullPath, catalog) !== null) {
                    const category = getCategory(fullPath, catalog);

                    const datasets = category
                      ? category.datasetIdentifiers.map(id => ({
                        ...catalog.datasets[id],
                        identifier: id,
                        categoryPath: fullPath
                      }))
                      : catalog.uncategorizedDatasets.map(ds => ({
                        ...ds,
                        categoryPath: ["uncategorized"]
                      }));

                    const subCategories = category
                      ? category.childrenCategories
                      : [];

                    return (
                      <Results
                        hub={hub}
                        node={node}
                        nodeCode={nodeCode}
                        isDefault={isDefault}
                        catalog={catalog}
                        categoryPath={fullPath}
                        datasets={datasets}
                        subCategories={subCategories.map(c => ({
                          ...c,
                          image: c.image ? baseURL + c.image : undefined
                        }))}
                        isAccessible={!!a11yParam}
                        onAccessibleDatasetFetch={setAccessibleDataset}
                      />
                    );

                    /* url for dataset */
                  } else if (
                    (fullPath.length === 2 && fullPath[0] === "uncategorized" && getDataset(fullPath[1], catalog) !== null) ||
                    (getCategory(fullPath.slice(0, fullPath.length - 1), catalog) !== null && getDataset(fullPath[fullPath.length - 1], catalog) !== null)
                  ) {
                    const dataset = getDataset(fullPath[fullPath.length - 1], catalog);
                    return (
                      <DatasetDomain
                        hub={hub}
                        node={node}
                        nodeCode={nodeCode}
                        isDefault={isDefault}
                        catalog={catalog}
                        categoryPath={fullPath.slice(0, fullPath.length - 1)}
                        datasetId={fullPath[fullPath.length - 1]}
                        datasetTitle={dataset.title}
                        notes={dataset?.note}
                        attachedFiles={dataset?.attachedDataFiles}
                        referenceMetadataUrl={dataset?.referenceMetadata}
                        viewId={viewParam || undefined}
                        isAccessible={!!a11yParam}
                      />
                    );

                  } else {
                    return (
                      <Call cb={goToNode} cbParam={nodeCode}>
                        <span />
                      </Call>
                    );
                  }
                }
              }}
            />
            <Route
              path='/:lang/:nodeCode/:datasetId'
              exact
              render={({match, location}) => {
                if (areNodeAndCatalogOk && !catalog.isEmpty) {

                  const a11yParam = new URLSearchParams(location.search).get("accessible");
                  const viewParam = new URLSearchParams(location.search).get("view");
                  const datasetId = match.params.datasetId;
                  const dataset = getDataset(datasetId, catalog);

                  /* short url for dataset */
                  if (dataset) {
                    return (
                      <DatasetDomain
                        hub={hub}
                        node={node}
                        nodeCode={nodeCode}
                        isDefault={isDefault}
                        catalog={catalog}
                        categoryPath={[]}
                        datasetId={datasetId}
                        datasetTitle={dataset.title}
                        notes={dataset?.note}
                        attachedFiles={dataset?.attachedDataFiles}
                        referenceMetadataUrl={dataset?.referenceMetadata}
                        viewId={viewParam || undefined}
                        isAccessible={!!a11yParam}
                      />
                    );

                  } else {
                    return (
                      <Call cb={goToNode} cbParam={nodeCode}>
                        <span/>
                      </Call>
                    )
                  }
                }
              }}
            />
            <Route
              render={() => {
                goToNode(nodeCode);
              }}
            />
          </Switch>
          <Call
            cb={({nodeId, datasetId, criteria, datasetTitle}) => {
              const exportParams = {
                labelFormat: LABEL_FORMAT_SELECTOR_LABEL_FORMAT_NAME
              };

              onDownloadSubmit(
                nodeId,
                datasetId,
                datasetTitle,
                DOWNLOAD_FORMAT_CSV,
                getDownloadFormatExtensionFromFormat(DOWNLOAD_FORMAT_CSV),
                false,
                criteria,
                null,
                exportParams,
                t
              );

              setAccessibleDataset(null);
            }}
            cbParam={{
              nodeId: node?.nodeId,
              datasetId: accessibleDataset?.identifier,
              criteria: {},
              datasetTitle: accessibleDataset?.title
            }}
            disabled={!accessibleDataset}
          >
            <span/>
          </Call>
        </Call>
      </Call>
    </div>
  )
};

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(NodeDomain);